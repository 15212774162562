export const environment = {
  production: false,

  // Api
  userServiceUrl: 'https://teccom-backend-userservice-qa.azurewebsites.net',
  notificationServiceUrl: 'https://teccom-backend-notificationservice-qa.azurewebsites.net',
  translationServiceUrl: 'https://teccom-backend-translationservice-qa.azurewebsites.net',
  organizationServiceUrl: 'https://teccom-backend-organizationservice-qa.azurewebsites.net',

  // Token
  allowedDomains: ['teccom-backend-organizationservice-qa.azurewebsites.net', 'teccom-backend-notificationservice-qa.azurewebsites.net', 'teccom-backend-userservice-qa.azurewebsites.net'],

  // Excluded domains for internal token
  excludedDomains: ['teccom-backend-userservice-qa.azurewebsites.net', 'teccom-backend-notificationservice-qa.azurewebsites.net'],
  
  // Okta
  oktaOptions: {
    clientId: '0oa1rn356wpb9hpSg0x7',
    issuerUri: 'https://login.qa.tecalliance.cloud/oauth2/default',
    redirectUri: `${window.location.origin}/callback`,
    postLogoutRedirectUri: 'https://teccom.qa.tecalliance.cloud/newapp/auth/welcome'
  },

  // Remotes
  microfrontends: {
    dashboard: {
      remoteUri: 'https://teccom-portal-dashboard-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'dashboard',
      exposedModule: ['DashboardModule'],
    },
    orders: {
      remoteUri: 'https://teccom-portal-orders-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'orders',
      exposedModule: ['OrdersModule'],
    },
    returns: {
      remoteUri: 'https://teccom-portal-returns-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'returns',
      exposedModule: ['ReturnsModule', 'ReturnsPublicModule'],
    },
    invoices: {
      remoteUri: 'https://teccom-portal-invoices-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'invoices',
      exposedModule: ['InvoicesModule'],
    },
    reports: {
      remoteUri: 'https://teccom-portal-reports-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'reports',
      exposedModule: ['AppModule'],
    },
    monitoring: {
      remoteUri: 'https://teccom-portal-monitoring-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'monitoring',
      exposedModule: ['MonitoringModule'],
    },
    administration: {
      remoteUri: 'https://teccom-portal-administration-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'administration',
      exposedModule: ['AdministrationModule'],
    },
    network: {
      remoteUri: 'https://teccom-portal-network-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'network',
      exposedModule: ['NetworkModule'],
    },
    connect: {
      remoteUri: 'https://teccom-portal-connect-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'connect',
      exposedModule: ['ConnectModule'],
    },
    internal: {
      remoteUri: 'https://teccom-portal-internal-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'internal',
      exposedModule: ['InternalModule'],
    },
    articleData: {
        remoteUri: 'https://teccom-portal-articledata-qa.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'articledata',
        exposedModule: ['ArticleDataModule']
    },
    maintenance: {
      remoteUri: 'https://teccom-portal-maintenance-qa.azurewebsites.net/',
      remoteFile: 'remoteEntry.js',
      remoteName: 'maintenance',
      exposedModule: ['MaintenanceModule']
    }
  },

  // Emails
  supportEmail: 'support@tecalliance.net',

  // Uri
  tecComWikiUri: 'https://help-teccom.tecalliance.net/en/home',
  statusPageUri: 'https://status.tecalliance.net/',
  tecallianceWebSite: 'https://www.tecalliance.net/',
  supportPageUri: 'https://ordersupportweb-qa-frontend.azurewebsites.net/',

  // TODO Remove this when migration is done
  legacyDomainSuffix: '.qa.tecalliance.cloud',
  legacyUserProfileUri: 'https://teccom.qa.tecalliance.cloud/newapp/user/profile',
  legacySubscriptionUri: 'https://teccom.qa.tecalliance.cloud/newapp/user/subscription',
  legacyOrganizationUri: 'https://teccom.qa.tecalliance.cloud/newapp/administration/organizations',
  legacyWebinarUri: 'https://teccom.qa.tecalliance.cloud/newapp/info/webinars',
  legacyReleaseNotesUri: 'https://teccom.qa.tecalliance.cloud/newapp/info/release-notes',
  legacyLegalNotice: 'https://teccom.qa.tecalliance.cloud/newapp/imprint-info'
};
